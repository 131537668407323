import React from "react";

import Layout from "../../components/layout";
import { Helmet } from 'react-helmet';

export default function Newsroom() {
    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Newsroom | Clean Air Filter</title>
        </Helmet>
        <section>
            <h1><span className="tail"><span className="head">Clean Air</span> Industry News.</span></h1>
            <p>More news coming soon!</p>
        </section>
    </Layout>;
}
